<template>
  <div>
    <h1>月次集計状況</h1>
    <v-card outlined class="mb-5">
      <v-expansion-panels flat v-model="panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col colspan="12">
                <DatePicker
                  class="mt-10"
                  v-model="date"
                  label="対象年月"
                  type="month"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col colspan="12">
                <v-autocomplete
                  v-model="selectFilterAffiliations"
                  :items="filterAffiliations"
                  label="所属"
                  clearable
                  multiple
                  chips
                  outlined
                  item-text="name"
                  item-value="code"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col colspan="6">
                <v-text-field
                  v-model="filterUserCode"
                  label="社員番号"
                  outlined
                  clearable
                  dense
                ></v-text-field>
              </v-col>
              <v-col colspan="6">
                <v-text-field
                  v-model="filterUserName"
                  label="社員名"
                  outlined
                  clearable
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col colspan="6">
                <v-radio-group
                  v-model="filterCalc"
                  row
                  label="月次集計状況:"
                  prepend-icon="mdi-toggle-switch"
                >
                  <v-radio
                    label="すべて"
                    :value="SelectsConfirm.Default"
                  ></v-radio>
                  <v-radio label="未実施" :value="SelectsConfirm.No"></v-radio>
                  <v-radio label="実施済" :value="SelectsConfirm.Yes"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col colspan="6">
                <v-radio-group
                  v-model="filterApproval"
                  row
                  label="承認状況:"
                  prepend-icon="mdi-toggle-switch"
                >
                  <v-radio
                    label="すべて"
                    :value="SelectsConfirm.Default"
                  ></v-radio>
                  <v-radio label="未実施" :value="SelectsConfirm.No"></v-radio>
                  <v-radio label="実施済" :value="SelectsConfirm.Yes"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col colspan="6">
                <v-radio-group
                  v-model="filtrerConfirm"
                  row
                  label="給与確定状況:"
                  prepend-icon="mdi-toggle-switch"
                >
                  <v-radio
                    label="すべて"
                    :value="SelectsConfirm.Default"
                  ></v-radio>
                  <v-radio label="未確定" :value="SelectsConfirm.No"></v-radio>
                  <v-radio label="確定済" :value="SelectsConfirm.Yes"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col colspan="6">
                <v-radio-group
                  v-model="filterAbsence"
                  row
                  label="欠勤:"
                  prepend-icon="mdi-toggle-switch"
                >
                  <v-radio
                    label="すべて"
                    :value="SelectsConfirm.Default"
                  ></v-radio>
                  <v-radio label="なし" :value="SelectsConfirm.No"></v-radio>
                  <v-radio label="あり" :value="SelectsConfirm.Yes"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col colspan="6">
                <v-radio-group
                  v-model="filterWorkSchedule"
                  row
                  label="勤務表の状態:"
                  prepend-icon="mdi-toggle-switch"
                >
                  <v-radio
                    label="すべて"
                    :value="SelectsConfirm.Default"
                  ></v-radio>
                  <v-radio
                    label="不備なし"
                    :value="SelectsConfirm.No"
                  ></v-radio>
                  <v-radio
                    label="不備あり"
                    :value="SelectsConfirm.Yes"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <!-- <v-col colspan="6">
                <v-radio-group
                  v-model="filterWithOutTime"
                  row
                  label="打刻なしの検索:"
                  prepend-icon="mdi-toggle-switch"
                >
                  <v-radio label="する" :value="SelectsConfirm.Yes"></v-radio>
                  <v-radio label="しない" :value="SelectsConfirm.No"></v-radio>
                </v-radio-group>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  v-model="LostStamp"
                  label="打刻漏れ"
                  :disabled="filterWorkSchedule != '1'"
                  checked
                ></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  v-model="NoCalc"
                  label="時間未計算"
                  :disabled="filterWorkSchedule != '1'"
                  checked
                ></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  v-model="NotStamp"
                  label="未打刻"
                  :disabled="filterWorkSchedule != '1'"
                ></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  v-model="HolyWork"
                  label="公休出勤"
                  :disabled="filterWorkSchedule != '1'"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions class="mx-2">
        <p style="color:red">
          対象年月に打刻が無い場合は、一覧に表示されません
        </p>
        <v-spacer></v-spacer>
        <Btn color="info" icon="file-import" @click="getselect()">取得</Btn>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-card-actions class="ma-2">
        <span style="color:red"
          >Excelダウンロードする場合は、社員の□に☑を入れてください。</span
        >
        <v-spacer />
        <!-- <Btn
          icon="calculator"
          color="primary"
          text
          outlined
          @click="calcwork"
          :disabled="!selected.length"
        >
          時間計算
        </Btn>
        <Btn
          icon="calculator"
          color="primary"
          text
          outlined
          @click="calc"
          :disabled="!selected.length"
        >
          金額計算
        </Btn> -->
        <Btn
          icon="mdi-file-excel"
          color="success"
          text
          outlined
          @click="download"
          :disabled="!selected.length"
        >
          出勤簿ダウンロード
        </Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="簡易検索"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
        :item-key="itemKey"
        multi-sort
        @toggle-select-all="selectAllToggle"
      >
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            :readonly="item.disabled"
            @input="select($event)"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <Btn color="info" text small @click="details(item)">確認</Btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { BUSYO_CODE as BusyoCode } from "@/defines";
const DATE = new Date();

const SelectsConfirm = {
  Default: "9",
  No: "0",
  Yes: "1"
};
export default {
  name: "ForCalc",
  mixins: [Common, ShowDialogs, Api],
  props: {},
  data() {
    return {
      date: `${DATE.getFullYear()}-${DATE.getMonth() + 1}-${DATE.getDate()}`,
      selected: [],
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      disabledCount: 0,
      displayDate: "",
      SelectsConfirm,
      filtrerConfirm: SelectsConfirm.Default,
      filterAffiliations: [],
      selectFilterAffiliations: [],
      filterUserCode: "",
      filterUserName: "",
      filterCalc: SelectsConfirm.Default,
      filterApproval: SelectsConfirm.Default,
      filterAbsence: SelectsConfirm.Default,
      filterWorkSchedule: SelectsConfirm.Default,
      filterWithOutTime: SelectsConfirm.No,
      panel: null
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    }
  },
  methods: {
    selectAllToggle(props) {
      console.log("selectAllToggle", props);
      if (!props.value) {
        this.selected = [];
        return;
      }
      if (this.selected.length != this.items.length - this.disabledCount) {
        this.selected = [];
        props.items.forEach(item => {
          //if (!item.disabled) {
          this.selected.push(item);
          //}
        });
      } else this.selected = [];
    },
    async details(item) {
      console.log("details", item);
      const paymentDate = await this.getPaymentDate();
      const ymd = paymentDate.split("-");
      let showConfirm = false;
      if (
        (item.approvalStatus === "1" && this.$store.state.authority === "5") ||
        (item.approvalStatus === "1" && this.$store.state.authority === "9")
      ) {
        showConfirm = true;
      }
      const param = {
        userincode: item.incode,
        targetemployees: this.items,
        targetUsers: this.items,
        targetdate: ymd[0] + "-" + ymd[1] + "-01",
        showConfirm: showConfirm,
        filter: {
          paymentDate: paymentDate,
          confirmFlag: this.filtrerConfirm,
          userCode: this.filterUserCode,
          userName: this.filterUserName,
          affiliationIds: this.selectFilterAffiliations,
          calcFlag: this.filterCalc,
          approvalFlag: this.filterApproval,
          absenceFlag: this.filterAbsence,
          attendanceFlag: this.filterWorkSchedule,
          timeFlag: "0",
          loststamp: this.LostStamp == true ? "1" : "0",
          nocalc: this.NoCalc == true ? "1" : "0",
          notstamp: this.NotStamp == true ? "1" : "0",
          holidaywork: this.HolyWork == true ? "1" : "0"
        }
      };
      let name = "Attendance";
      if (item.code1 === String(BusyoCode.WELFARE))
        name = "WelfareAttendanceDetails";

      this.$router.push({ name: name, params: param });
    },
    async getdata() {
      console.log("getdata");
      try {
        this.filterAffiliations = await this.$get(
          this.Paths.shozokuFilterForCalc
        );
        const data = await this.$get(this.Paths.forCalc);
        await this.setdata(data);
        this.displayDate = await this.getPaymentDate();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getselect() {
      const authority = this.$store.state.authority;

      // 現場リーダーの場合は、所属未選択は不可とする
      if (authority === "1" && this.selectFilterAffiliations.length === 0) {
        this.$error("所属を1つ以上選択してください。");
        return;
      }

      if (
        this.filterWorkSchedule == "1" &&
        this.LostStamp == false &&
        this.NoCalc == false &&
        this.NotStamp == false &&
        this.HolyWork == false
      ) {
        this.$error(
          "勤務表の状態が「不備あり」の場合は、\n不備のパターンを1つ以上✔してください。"
        );
        return;
      }

      const paymentDate = await this.getPaymentDate();
      const param = {
        paymentDate: paymentDate,
        confirmFlag: this.filtrerConfirm,
        userCode: this.filterUserCode,
        userName: this.filterUserName,
        affiliationIds: this.selectFilterAffiliations,
        calcFlag: this.filterCalc,
        approvalFlag: this.filterApproval,
        absenceFlag: this.filterAbsence,
        attendanceFlag: this.filterWorkSchedule,
        timeFlag: "0",
        loststamp: this.LostStamp == true ? "1" : "0",
        nocalc: this.NoCalc == true ? "1" : "0",
        notstamp: this.NotStamp == true ? "1" : "0",
        holidaywork: this.HolyWork == true ? "1" : "0"
      };

      await this.getSelectWithParam(param);
    },
    async getSelectWithParam(param) {
      console.log("getselect");
      this.$loading();
      try {
        this.selected = [];
        this.disabledCount = 0;
        const encode = encodeURI(JSON.stringify(param));
        console.log("encode", encode);
        const data = await this.$get(this.Paths.forCalc, "query=" + encode);
        await this.setdata(data);
        this.displayDate = param.paymentDate;
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async setdata(data) {
      console.log("setdata");

      this.headers = data.headers;
      this.headers.push({ text: "actions", value: "actions" });
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;

      this.items.map(item => {
        if (item.disabled) this.disabledCount += 1;
      });

      this.displayDate = "";
    },
    async calcwork() {
      console.log("calc", this.selected);
      this.selected = this.selected.filter(e => !e.disabled);
      if (this.selected.length == 0) {
        this.$warning("社員が選択されていません");
        return;
      }

      const param = await this.getParamsForCalc();
      console.log("calc-work", param);
      this.$loading();

      try {
        await this.$post(this.Paths.attendanceMultiCalc, param);
        this.$info("時間計算を実行しました");
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async calc() {
      console.log("calc", this.selected);
      this.selected = this.selected.filter(e => !e.disabled);
      if (this.selected.length == 0) {
        this.$warning("社員が選択されていません");
        return;
      }
      const param = await this.getParamsForCalc();
      console.log("attendanceMultiSalaryCalc", param);
      this.$loading();

      try {
        await this.$post(this.Paths.attendanceMultiSalaryCalc, param);
        this.$info("月次集計を実行しました");
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getParamsForCalc() {
      const incodes = [];
      this.selected.forEach(s => {
        incodes.push(Number(s.incode));
      });

      const date = new Date(this.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const targetmonth = year + "-" + ("00" + month).slice(-2);

      const targetFromDate = new Date(year, month - 1, 21);

      const targetToDate = new Date(
        targetFromDate.getFullYear(),
        targetFromDate.getMonth() + 1,
        20
      );

      const param = {
        targetmonth: targetmonth,
        employeeId: incodes,
        workdatefrom: await this.getTargetDateString(targetFromDate),
        workdateto: await this.getTargetDateString(targetToDate)
      };

      return param;
    },
    async download() {
      console.log("download", this.selected);
      if (this.selected.length == 0) {
        this.$warning("社員が選択されていません");
        return;
      }

      const paymentDate = await this.getPaymentDate();
      if (paymentDate !== this.displayDate) {
        this.$warning("選択した年月と表示しているデータが異なります。");
        return;
      }

      this.$loading();

      try {
        const date = new Date(this.date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const targetmonth = year + "-" + ("00" + month).slice(-2);

        const incodes = [];
        this.selected.forEach(s => {
          incodes.push(s.incode);
        });

        const param = {
          incodes: incodes,
          targetmonth: targetmonth
        };

        const encode = encodeURI(JSON.stringify(param));
        console.log("encode", encode);
        const result = await this.$downloadexcel(
          "/attendance-excel",
          "query=" + encode
        );

        const url = URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.download = "download-filename.zip";
        link.click();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getTargetDateString(targetDate) {
      return (
        targetDate.getFullYear() +
        "-" +
        targetDate.getMonth() +
        "-" +
        targetDate.getDate()
      );
    },
    async getPaymentDate() {
      const ym = this.date.split("-");
      const dt = new Date(Number(ym[0]), Number(ym[1]), 1);
      dt.setDate(dt.getDate() - 1);

      const result =
        dt.getFullYear() +
        "-" +
        ("00" + Number(dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + dt.getDate()).slice(-2);

      return result;
    }
  },
  async created() {
    console.log("==created");
    this.date = this.$getPmServiceDate();
    console.log(this.date);
    await this.getdata();
    console.log("query", this.$route.query);
    if ("filter" in this.$route.query) {
      const param = this.$route.query.filter;
      if (typeof param !== "undefined" && Object.keys(param).length) {
        this.filtrerConfirm = param.confirmFlag;
        this.filterUserCode = param.userCode;
        this.filterUserName = param.userName;
        this.selectFilterAffiliations = param.affiliationIds;
        this.filterCalc = param.calcFlag;
        this.filterApproval = param.approvalFlag;
        this.filterAbsence = param.absenceFlag;
        this.date = param.paymentDate;
        this.filterWorkSchedule = param.attendanceFlag;
        this.LostStamp = param.loststamp == "1" ? true : false;
        this.NoCalc = param.nocalc == "1" ? true : false;
        this.NotStamp = param.notstamp == "1" ? true : false;
        this.HolyWork = param.holidaywork == "1" ? true : false;
        await this.getSelectWithParam(param);
      } else {
        this.LostStamp = true;
        this.NoCalc = true;
        this.NotStamp = true;
        this.HolyWork = true;
      }
    } else {
      this.LostStamp = true;
      this.NoCalc = true;
      this.NotStamp = true;
      this.HolyWork = true;
    }
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>

<style scoped>
.dialog-padding {
  padding-top: 0px;
}
</style>
